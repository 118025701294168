import { AnnouncementBar } from "@bluebottlecoffee/design-system";

export const DraftModeBar = ({ copy, cta }: { copy: string; cta: string }) => {
  const base =
    process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
      ? "http://localhost:8788"
      : "https://bbcstaging.com";
  const disableUrl = `${base}/api/v1/draft/disable`;

  return (
    <AnnouncementBar
      barColor="black"
      announcements={[
        {
          announcement: {
            text: `*** ${copy} | <a href="${disableUrl}">${cta}</a> ***`,
          },
        },
      ]}
    />
  );
};
