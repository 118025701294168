import { createClient } from "@sanity/client";
import type { SanityClient } from "next-sanity";
import { format } from "date-fns";
import { isFeatureEnabled } from "./utils/is-feature-enabled";

type SanityClientArgs = {
  draftMode?: boolean;
  projectId?: string;
  dataset?: string;
  token?: string;
  useCdn?: boolean;
  apiVersion?: string;
};

const getEnvVars = () =>
  typeof process !== "undefined"
    ? {
        projectId: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_PROJECT_ID,
        dataset: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET,
        token: process.env.SANITY_STUDIO_TOKEN,
        apiVersion: process.env.NEXT_PUBLIC_SANITY_STUDIO_API_VERSION,
      }
    : {};

export default (args?: SanityClientArgs): SanityClient => {
  const defaults = { draftMode: false, ...{ ...getEnvVars() } };
  const options = { ...defaults, ...args };
  const { apiVersion, dataset, draftMode, projectId, token } = options;
  return createClient({
    projectId,
    dataset: dataset || "us-staging",
    token,
    useCdn: draftMode
      ? false
      : isFeatureEnabled("NEXT_PUBLIC_ENABLE_SANITY_CDN"),
    apiVersion: apiVersion || format(new Date(), "yyyy-MM-dd"),
    perspective: draftMode ? "drafts" : "published",
    stega: {
      enabled: !!draftMode,
      studioUrl:
        // yes it only works this way :)
        typeof process !== "undefined" &&
        process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
          ? "http://localhost:3333/development"
          : "https://studio.bluebottlecoffee.dev/us-staging",
    },
  });
};
