import {
  ScreenSizeContextProvider,
  SlideOverDialogContextProvider,
} from "@bluebottlecoffee/design-system/components";
import "@bluebottlecoffee/design-system/dist/index.css";
import { ChordProvider } from "@chordcommerce/react-autonomy";
import Honeybadger from "@honeybadger-io/js";
import cookies from "js-cookie";
import Head from "next/head";
import { lazy, useCallback } from "react";
import "../styles/globals.css";
import { ConditionalConsentManager } from "../components/ConsentManagerWrapper";
import { ConditionalEmailOptInModal } from "../components/ConditionalEmailOptInModal";
import { ConditionalGiftCardRedemptionModal } from "../components/ConditionalGiftCardRedemption";
import { ConditionalGiftSubRedemptionModal } from "../components/ConditionalGiftSubRedemption";
import { DraftModeBar } from "../components/DraftModeBar";
import cognitoAuth from "../lib/chord/services/auth";
import { setBreadcrumbsCookies } from "../lib/component-utils/breadcrumbs-set-cookies";
import { productPage, buildAbsoluteUrl } from "../lib/link-builders";
import { assignAlgoliaProps } from "../lib/segment/algolia-middleware";
import { assignEventProps } from "../lib/segment/middleware";
import {
  getIsClientSide,
  setIterableRevenueAttributionCookies,
} from "../lib/utils";
import beforeNotifyHandler from "../lib/utils/honeybadger-before-notify";

const PreviewProvider = lazy(() => import("../components/PreviewProvider"));

Honeybadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  revision: process.env.NEXT_PUBLIC_HONEYBADGER_REVISION,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  projectRoot: "webpack://_N_E/",
  // uncomment to report errors in development
  // reportData: true,
});
Honeybadger.beforeNotify(beforeNotifyHandler);

function MyApp({ Component, pageProps }) {
  const { token, system } = pageProps;
  // system fallback for pages that do not use props e.g. magic_link_callback
  const { draftModeEnabled, draftModeDisableCta } = system || {};

  const draftMode =
    process.env.NEXT_PUBLIC_ENVIRONMENT !== "production" &&
    cookies.get("prerender_bypass") === "true";

  setIterableRevenueAttributionCookies();
  setBreadcrumbsCookies({ pageProps, numberOfCrumbs: 3 });

  // Add Segment Middleware
  if (getIsClientSide()) {
    window.analytics.addSourceMiddleware(assignEventProps);
    window.analytics.addSourceMiddleware(assignAlgoliaProps);
  }

  // Using useCallback here so that the `config` object passed to <ChordProvider> doesn't cause a re-render
  const productUrlFormatter = useCallback((product) => {
    const path = productPage({
      slug: product.variant?.slug,
      lang: pageProps.lang,
      region: pageProps.region,
    });
    return buildAbsoluteUrl({ path });
  }, []);

  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID && (
          <meta
            name="google-site-verification"
            content={process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID}
          />
        )}
      </Head>
      <div id="modal-root" />
      <ChordProvider
        config={{
          productUrlFormatter,
          domain: process.env.NEXT_PUBLIC_CHORD_OMS_API_URL,
          brandName: process.env.NEXT_PUBLIC_CHORD_OMS_BRAND_NAME,
          currency: "USD",
          locale: "en-US",
          omsId: process.env.NEXT_PUBLIC_CHORD_OMS_ID,
          storeId: process.env.NEXT_PUBLIC_CHORD_OMS_STORE_ID,
          tenantId: process.env.NEXT_PUBLIC_CHORD_OMS_TENANT_ID,
          storeSlug: process.env.NEXT_PUBLIC_CHORD_OMS_STORE_NAME,
        }}
        auth={cognitoAuth}
      >
        <SlideOverDialogContextProvider>
          <ScreenSizeContextProvider>
            <ConditionalGiftSubRedemptionModal pageProps={pageProps} />
            <ConditionalGiftCardRedemptionModal pageProps={pageProps} />
            <ConditionalEmailOptInModal pageProps={pageProps} />
            <ConditionalConsentManager pageProps={pageProps}>
              {draftMode ? (
                <PreviewProvider draftMode={draftMode} token={token}>
                  <DraftModeBar
                    copy={draftModeEnabled}
                    cta={draftModeDisableCta}
                  />
                  <Component {...pageProps} draftMode={draftMode} />
                </PreviewProvider>
              ) : (
                <Component {...pageProps} />
              )}
            </ConditionalConsentManager>
          </ScreenSizeContextProvider>
        </SlideOverDialogContextProvider>
      </ChordProvider>
    </>
  );
}

export default MyApp;
