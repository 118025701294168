/* eslint-disable no-use-before-define */
import {
  CartLineItem,
  Image,
  Money,
  OptionValue,
  Order,
  OrderAddress,
  OrderLineItem,
  Payment,
  SubscriptionInterval,
  SubscriptionOption,
} from "@bluebottlecoffee/design-system/components/lib/types";
import { QueryParam } from "@bluebottlecoffee/design-system/components/lib/types/router";
import { ParsedUrlQuery } from "querystring";
import {
  AppPlayLinksProps,
  CartReminderLines,
  LoginFormCopy,
} from "@bluebottlecoffee/design-system/components";
import { addDays, addMonths } from "date-fns";
import Dinero, { Currency } from "dinero.js";
import cookie from "js-cookie";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import {
  Address,
  Adjustment,
  Image as ChordImage,
  LineItem,
  OrderBig,
  Payment as ChordPayment,
} from "@chordcommerce/react-autonomy";
import { DereferencedQuickShopCarouselProps } from "../components/QuickShopCarouselWrapper";
import { ConversionCopy } from "../pages/[region]/[lang]/product/[slug]";
import sanityClient from "./sanity-client";
import {
  AriaTranslations,
  BaseSanityQueryType,
  CartQueryType,
  CafesPageQueryType,
  sharedSanityCafesPageQuery,
  sharedSanityCartQuery,
  sharedSanityQuery,
  SubscribableProductsSchema,
} from "./sanity/shared";
import { toCopyObject } from "./sanity/translation-group-queries";
import { productPage } from "./link-builders";
import { ConsentManagerCopyProps } from "../components/ConsentManagerWrapper";
import { ConversionSchema } from "./transformers/conversion";
import { SearchDialogCopy } from "../components/SearchDialog";
import { AlgoliaRecommendProduct } from "./algolia/types";
import localization from "../localization";
import { REGION } from "./i18n";
import {
  ChordLineItem,
  ChordOrder,
  ChordResponsiveImage,
} from "./chord/types/chord.types";
import { Dialect } from "./utils/locale";
import { isFeatureEnabled } from "./utils/is-feature-enabled";
import { FlavorProfileCopyTranslations } from "./transformers/flavor-profile";
import { AccountMenuCopy } from "./account-menu";
import { ArticleCardCarousel, Homepage } from "./sanity-schema";
import { quickShopCarouselCardQuery } from "./sanity/quick-shop-carousel-queries";
import { System } from "./utils/system";

/**
 * Checks that the @param argument is not `undefined`
 *
 * @param {(T | undefined)} argument The value to check against
 * @return {bool} Whether the argument is not equal to `undefined`
 * */
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined && argument != null;
}

/**
 * Returns the type provided to an array type
 *
 * ### Example
 * ```ts
 * ElementOf<any[]>; // any
 * ElementOf<number[]>; // number
 * ElementOf<(string | MyClass)[]>; // string | MyClass
 * ```
 */
export type ElementOf<ArrayType extends Array<any>> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type DebugPrintCallback = (message: any) => void;

/**
 * Deduplicates an array
 *
 * Used as a callback to `filter`.
 *
 * ## Example
 * ```ts
 * [1,2,2,3].filter(onlyUnique); // [1,2,3]
 * ```
 */
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/**
 * Generates a random key.
 * Useful to render React components properly
 *
 * @returns string
 */

export function generateRandomKey() {
  return Math.random().toString(36).substring(2);
}

/**
 * Used with a value that can be either a value or an array.
 *
 * Commonly used with query params.
 *
 * ## Example
 * ```ts
 * const myFilterArray = ["Washed", "Floral/Fruity"];
 * const myFilterString = "Light";
 *
 * return valueOrFirst(myFilterArray); // "Washed"
 * return valueOrFirst(myFilterString); // "Light"
 * ```
 */
export function valueOrFirst<T>(value: T | T[]): T {
  if (Array.isArray(value) && value.length > 0) return value[0];

  return value as T;
}

/** Verifies the node environment is set to "development" */
export const getIsDebug = () => process.env.NODE_ENV === "development";

/**
 * Verifies the global `window` object is available, signifying we're running
 * in a browser and not inside of a nodejs environment.
 */
export const getIsClientSide = () => typeof window !== "undefined";

/**
 * Used to build a `debugPrint` function for a class.
 *
 * The returned `debugPrint` function will only log messages to the console when
 * the 'development' node environment.
 *
 * ##Usage
 * ```ts
 * const debugPrint = debugPrintBuilder("myClassName");
 * ...
 * debugPrint("in someFunction"); // myClassName: in someFunction
 * ```
 * ## Output Format
 * Message format when generating the static site @classPrefix|SSG: @message
 * Message format when rendering client side @classPrefix: @message
 * @param classPrefix the class you're executing your debug statements in
 * @param message the message or object you want to log. Objects should be sent
 * on their own and not interpolated inside a string or the object's toString
 * method will be used to serialize the object as a string and all you'll see
 * is `[object: Object]` inside of the log message.
 */
export const debugPrintBuilder =
  (classPrefix: string): DebugPrintCallback =>
  (message: any): void => {
    const ssgMarker = getIsClientSide() ? "" : "|SSG";

    if (getIsDebug()) {
      if (typeof message === "object") {
        // eslint-disable-next-line no-console
        console.log(`${classPrefix}${ssgMarker}:`);

        // eslint-disable-next-line no-console
        console.log(message);
      } else {
        // eslint-disable-next-line no-console
        console.log(`${classPrefix}${ssgMarker}: ${message}`);
      }
    }
  };

type GeneratedStaticProps = {
  region: string;
  lang: string;
  slug: string;
};

/**
 * Gets the generated query params from static props or the router's query.
 *
 * These arguments are available at all times including during static site
 * rendering (SSG - aka Static Site Generation) so you can use these at any time.
 *
 * ## Where do they come from?
 * The shape of our url is as follows:
 * http://domain.com/[region]/[lang]/page/[slug]?other=query&params=here.
 *
 * ## Usage
 *
 * ### GetStaticProps
 * ```ts
 export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);
  ...
 }
 * ```
 *
 * ### NextRouter
 * ```ts
 * const router = useRouter();
 *
  * const { region, lang, slug } = getPageArgs(router.query);
  *
  * // Note, for any other query related arguments you expect to be changed on
  * // the client or that you expect to be dynamic (anything not available
  * // during build time via some kind of query [Sanity, Algolia, etc.]) you'll
  * // want to wait until the router is ready. You'll also want to listen for
  * // changes to the router's path in case a user changes the query while on
  * // the page.
  *
  * useEffect(() => {
  * if(router.isReady) {
  *   // Do something with clientSide query params here.
  *   // If you only expect one param by the key you're looking for then you can
  *   // use the other util `valueOrFirst` to ensure you're only getting one
  *   // back.
  *   // const navQuery = valueOrFirst(router.query.nav);
  * }
  * }, [router.isReady, router.asPath])
 * ```
 */
export const getPageArgs = (query: ParsedUrlQuery): GeneratedStaticProps => {
  const { region: regionParam, lang: langParam, slug: slugParam } = query;
  return {
    region: valueOrFirst(regionParam!),
    lang: valueOrFirst(langParam!),
    slug: valueOrFirst(slugParam!),
  };
};

/**
 * Stringifies comma separates array types and simply returns strings
 */
export const getPrintableQueryParam = (param: QueryParam) =>
  Array.isArray(param) ? param.join(",") : param;

export const productSearchNormalize = (
  productSearch: BaseSanityQueryType["productSearch"],
  lang: string,
): SearchDialogCopy => ({
  productSearchCopy: toCopyObject<SearchDialogCopy["productSearchCopy"]>(
    lang,
    productSearch.productSearchCopy,
  ),
  shopcardCopy: {
    shippingCopy: productSearch.shopcardCopy.shippingCopy,
    conversionCopy: toCopyObject<ConversionCopy>(
      lang,
      productSearch.shopcardCopy.conversionCopy,
    ),
  },
});

/** use these for pages that do not have the MiniCart Slideover Dialog trigger */
export const getDefaultStaticProps = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    accountMenuCopy,
    announcementBar,
    appPlayLinks,
    aria,
    consentManagerCopy,
    cookiesNotice,
    emailOptIn,
    flavorProfileCopy,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    giftSubRedemption,
    loginDialogCopy,
    nav,
    navAndCartInfoBanner,
    productRecs,
    productSearch,
    system,
  } = await sanityClient().fetch<BaseSanityQueryType>(sharedSanityQuery(lang));

  return {
    props: {
      accountMenuCopy: toCopyObject<AccountMenuCopy>(lang, accountMenuCopy),
      announcementBar,
      appPlayLinks: toCopyObject<Omit<AppPlayLinksProps, "bgColor">>(
        lang,
        appPlayLinks,
      ),
      aria: toCopyObject<AriaTranslations>(lang, aria),
      cookiesNotice,
      emailOptIn,
      flavorProfileCopy: toCopyObject<FlavorProfileCopyTranslations>(
        lang,
        flavorProfileCopy,
      ),
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      giftSubRedemption,
      lang,
      nav,
      navAndCartInfoBanner,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: productSearchNormalize(productSearch, lang),
      system: toCopyObject<System>(lang, system),
    },
  };
};

export const getDefaultStaticPropsWithSlug = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);

  const {
    accountMenuCopy,
    announcementBar,
    appPlayLinks,
    aria,
    consentManagerCopy,
    cookiesNotice,
    emailOptIn,
    flavorProfileCopy,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    giftSubRedemption,
    loginDialogCopy,
    nav,
    navAndCartInfoBanner,
    productRecs,
    productSearch,
    system,
  } = await sanityClient().fetch<BaseSanityQueryType>(sharedSanityQuery(lang));

  return {
    props: {
      accountMenuCopy: toCopyObject<AccountMenuCopy>(lang, accountMenuCopy),
      announcementBar,
      appPlayLinks: toCopyObject<Omit<AppPlayLinksProps, "bgColor">>(
        lang,
        appPlayLinks,
      ),
      aria: toCopyObject<AriaTranslations>(lang, aria),
      cookiesNotice,
      emailOptIn,
      flavorProfileCopy: toCopyObject<FlavorProfileCopyTranslations>(
        lang,
        flavorProfileCopy,
      ),
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      giftSubRedemption,
      lang,
      nav,
      navAndCartInfoBanner,
      region,
      slug,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: productSearchNormalize(productSearch, lang),
      system: toCopyObject<System>(lang, system),
    },
  };
};

export const getDefaultStaticPropsCafesPage = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    accountMenuCopy,
    announcementBar,
    appPlayLinks,
    aria,
    cafesPage,
    consentManagerCopy,
    cookiesNotice,
    emailOptIn,
    flavorProfileCopy,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    giftSubRedemption,
    loginDialogCopy,
    nav,
    navAndCartInfoBanner,
    productRecs,
    productSearch,
    system,
  } = await sanityClient().fetch<CafesPageQueryType>(
    sharedSanityCafesPageQuery(lang),
  );

  return {
    props: {
      accountMenuCopy: toCopyObject<AccountMenuCopy>(lang, accountMenuCopy),
      announcementBar,
      appPlayLinks: toCopyObject<Omit<AppPlayLinksProps, "bgColor">>(
        lang,
        appPlayLinks,
      ),
      aria: toCopyObject<AriaTranslations>(lang, aria),
      cafesPage,
      cookiesNotice,
      emailOptIn,
      flavorProfileCopy: toCopyObject<FlavorProfileCopyTranslations>(
        lang,
        flavorProfileCopy,
      ),
      footer,
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      giftCardRedemption,
      giftSubRedemption,
      lang,
      nav,
      navAndCartInfoBanner,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: productSearchNormalize(productSearch, lang),
      system: toCopyObject<System>(lang, system),
    },
  };
};

/** use this for full cart page */
export const getDefaultStaticPropsMiniCart = async (context) => {
  const { region, lang } = getPageArgs(context.params);

  const {
    accountMenuCopy,
    announcementBar,
    appPlayLinks,
    aria,
    cart,
    consentManagerCopy,
    cookiesNotice,
    emailOptIn,
    flavorProfileCopy,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    giftSubRedemption,
    loginDialogCopy,
    nav,
    navAndCartInfoBanner,
    productRecs,
    productSearch,
    subscribableProducts,
    system,
  } = await sanityClient().fetch<CartQueryType>(sharedSanityCartQuery(lang));

  return {
    props: {
      accountMenuCopy: toCopyObject<AccountMenuCopy>(lang, accountMenuCopy),
      announcementBar,
      appPlayLinks: toCopyObject<Omit<AppPlayLinksProps, "bgColor">>(
        lang,
        appPlayLinks,
      ),
      aria: toCopyObject<AriaTranslations>(lang, aria),
      cart,
      cookiesNotice,
      emailOptIn,
      flavorProfileCopy: toCopyObject<FlavorProfileCopyTranslations>(
        lang,
        flavorProfileCopy,
      ),
      footer,
      giftCardRedemption,
      giftSubRedemption,
      lang,
      nav,
      navAndCartInfoBanner,
      region,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: productSearchNormalize(productSearch, lang),
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      subscribableProducts,
      system: toCopyObject<System>(lang, system),
    },
  };
};

/** use this for pages that have the MiniCart Slideover Dialog trigger (ie: Product pages) */
export const getDefaultStaticPropsMiniCartWithSlug = async (context) => {
  const { region, lang, slug } = getPageArgs(context.params);

  const {
    accountMenuCopy,
    announcementBar,
    appPlayLinks,
    aria,
    cart,
    consentManagerCopy,
    cookiesNotice,
    emailOptIn,
    flavorProfileCopy,
    footer,
    giftCardFormCopy,
    giftCardRedemption,
    giftSubRedemption,
    loginDialogCopy,
    nav,
    navAndCartInfoBanner,
    productRecs,
    productSearch,
    subscribableProducts,
    system,
  } = await sanityClient().fetch<CartQueryType>(sharedSanityCartQuery(lang));

  return {
    props: {
      accountMenuCopy: toCopyObject<AccountMenuCopy>(lang, accountMenuCopy),
      announcementBar,
      appPlayLinks: toCopyObject<Omit<AppPlayLinksProps, "bgColor">>(
        lang,
        appPlayLinks,
      ),
      aria: toCopyObject<AriaTranslations>(lang, aria),
      cart,
      cookiesNotice,
      emailOptIn,
      flavorProfileCopy: toCopyObject<FlavorProfileCopyTranslations>(
        lang,
        flavorProfileCopy,
      ),
      footer,
      giftCardRedemption,
      giftSubRedemption,
      lang,
      nav,
      navAndCartInfoBanner,
      region,
      slug,
      loginDialogCopy: toCopyObject<LoginFormCopy>(lang, loginDialogCopy),
      consentManagerCopy: toCopyObject<ConsentManagerCopyProps>(
        lang,
        consentManagerCopy,
      ),
      productRecs: productRecs.products,
      productSearch: productSearchNormalize(productSearch, lang),
      giftCardFormCopy: toCopyObject<GiftCardFormCopy>(lang, giftCardFormCopy),
      subscribableProducts,
      system: toCopyObject<System>(lang, system),
    },
  };
};

export const getDefaultStaticPaths = async () => {
  const params = localization.languages(REGION).map((lang) => ({
    params: {
      region: REGION,
      lang: lang.code,
    },
  }));

  return {
    paths: params,
    fallback: false,
  };
};

export const getDefaultStaticPathsMiniCart = async () => {
  const params = localization.languages(REGION).map((lang) => ({
    params: {
      region: REGION,
      lang: lang.code,
    },
  }));

  return {
    paths: params,
    fallback: false,
  };
};

export const bbcLogoFallBackImgSrc: URL["pathname"] =
  "/v1612883439/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png";

export const defaultImage: Image = { src: bbcLogoFallBackImgSrc, altText: "" };

/* <Image /> is set to unoptimized in the design system so full cloudinary URL is required
  for fallback image */
export const variantFallbackImageSmall: string =
  "https://res.cloudinary.com/hbhhv9rz9/image/upload/blue_bottle_brand_assets/Bottle_Logo-Small.001_031820.png";

/** Cart and MiniCart shared */
type CartVariantProps = {
  data: Partial<OrderBig>;
  dialect: Dialect;
  subscribableProducts?: SubscribableProductsSchema[];
  oneTimeLabel: string;
};

const normalizeSubscriptionOptionsCart = ({
  subscribableProducts,
  sku,
  oneTimeInterval,
}: {
  subscribableProducts: SubscribableProductsSchema[];
  sku: string;
  oneTimeInterval: SubscriptionInterval;
}) => {
  const subscriptionLineItem = subscribableProducts?.find((product) =>
    product.subscriptionOptions.some((options) => options.item.sku === sku),
  );

  const oneTimeLineItem = subscribableProducts?.find((product) =>
    product.variants.some((variant) => variant.sku === sku),
  );

  const subcription = subscriptionLineItem || oneTimeLineItem;
  if (!subcription || !subscriptionLineItem) return null;

  const isSubscriptionOnly = subcription.subscriptionType !== "single-variant";

  const intervals = isSubscriptionOnly
    ? subcription.subscription.intervals
    : [oneTimeInterval, ...subcription.subscription.intervals];

  return {
    intervals,
    options: subcription.subscriptionOptions as unknown as SubscriptionOption[],
  };
};

export function cartVariants({
  data,
  dialect,
  subscribableProducts,
  oneTimeLabel,
}: CartVariantProps): CartLineItem[] {
  const { lineItems } = data;

  const oneTimeInterval = {
    _id: "one-time-purchase",
    presentation: oneTimeLabel,
  } as SubscriptionInterval;

  if (lineItems) {
    return lineItems.map((lineItem) => {
      const {
        id,
        quantity,
        giftCards,
        subscriptionLineItems,
        variant: {
          // @ts-ignore
          cartLimit,
          displayPrice,
          images,
          name,
          optionsText,
          optionValues,
          price,
          // @ts-ignore
          regularPrice,
          sku,
          slug,
        },
      } = lineItem;
      const image = images?.[0];
      const imageAlt = image?.alt;
      const imageSrc =
        image?.smallUrl || image?.miniUrl || variantFallbackImageSmall;

      return {
        currency: data.currency,
        id,
        quantity,
        giftCards: giftCards as any[],
        variant: {
          cartLimit,
          displayPrice,
          images: [
            {
              desktop: {
                altText: imageAlt || "",
                src: imageSrc,
              },
              mobile: {
                altText: imageAlt || "",
                src: imageSrc,
              },
            },
          ],
          name,
          optionsText,
          optionValues: optionValues.map(
            ({ optionTypePresentation, presentation }) => ({
              optionTypePresentation,
              presentation,
            }),
          ),
          price: Number(price),
          regularPrice: regularPrice ? Number(regularPrice) : Number(price), // Chord sends `price` as `regularPrice` and `regularPrice` as `null` 🤷🏻‍♂️
          sku,
          slug: productPage({ slug, ...dialect }),
          subscriptions: subscriptionLineItems.length
            ? subscriptionLineItems
            : null,
          subscriptionOptions:
            isFeatureEnabled(
              process.env.NEXT_PUBLIC_CART_SUBSCRIPTION_TOGGLE_ENABLED,
            ) &&
            normalizeSubscriptionOptionsCart({
              subscribableProducts,
              sku,
              oneTimeInterval,
            }),
        },
      };
    });
  }
  return [];
}

export const cartReminderLines = (
  cart: Partial<OrderBig>,
): CartReminderLines => {
  const { lineItems, currency } = cart;
  return lineItems?.map((lineItem) => {
    const {
      variant,
      subscriptionLineItems,
      quantity: oneTimeQuantity,
    } = lineItem;
    const { images, name, price } = variant;
    const image = images?.[0];

    const isSubscriptions = Boolean(subscriptionLineItems.length);
    const quantity = isSubscriptions
      ? subscriptionLineItems[0].quantity
      : oneTimeQuantity;

    return {
      id: lineItem.id,
      image: {
        desktop: {
          src: image?.smallUrl || image?.miniUrl || variantFallbackImageSmall,
          altText: image?.alt || "",
        },
      },
      isSubscriptions,
      name,
      quantity,
      totalPrice: Number(price) * 100 * quantity,
      currency: currency as Currency,
    };
  });
};

type PromoEligible = {
  adjustments: Adjustment[];
  lineItems: { adjustments: Adjustment[] }[];
};

const allEligiblePromos = (data: Partial<OrderBig>): Adjustment[] | null => {
  const cartAdjustments: Adjustment[] = data?.adjustments;
  const lineItemsWithAdjustments: PromoEligible["lineItems"] =
    data?.lineItems?.filter((lineItem) => lineItem.adjustments?.length);
  const lineItemAdjustments: Adjustment[] = lineItemsWithAdjustments?.flatMap(
    (lineItem) => lineItem.adjustments,
  );
  const eligibleAdjustments: Adjustment[] = cartAdjustments
    ?.concat(lineItemAdjustments)
    ?.filter((adjustment) => adjustment.eligible);
  const eligiblePromos = eligibleAdjustments?.filter(
    (adj) => adj.sourceType === "Spree::PromotionAction",
  );
  return eligiblePromos ?? null;
};

export function discountTotal(data: Partial<OrderBig>): number {
  const promoAmounts: number[] =
    allEligiblePromos(data)?.map((promo) =>
      Math.round(parseFloat(promo.amount) * 100),
    ) ?? [];
  return promoAmounts?.reduce((partialSum, a) => partialSum + a, 0);
}

export function getPromoLabels(data: Partial<OrderBig>): string[] {
  const allPromosWithAmt: Adjustment[] =
    allEligiblePromos(data)?.filter((promo) => promo.amount !== "0.0") ?? [];
  const promosWithAmtLabels: string[] = allPromosWithAmt?.map(
    (promo) => promo.label,
  );
  const formattedLabels: string[] = promosWithAmtLabels?.map((label) =>
    label.replace(/promotion /gi, "").replace(/ \(Domain\)/gi, ""),
  );
  return formattedLabels?.filter(
    (adjustment, index) => formattedLabels?.indexOf(adjustment) === index,
  );
}

export function getPromoWithCode(data: Partial<OrderBig>): string | undefined {
  const allPromosWithCodes: Adjustment[] =
    allEligiblePromos(data)?.filter((promo) => promo.promotionCodeId) ?? [];
  const promoCodeValues: string[] = allPromosWithCodes?.map(
    (promo) => promo.promotionCode.value,
  );
  return promoCodeValues[0];
}

const freeShippingEnabled: boolean = isFeatureEnabled(
  process.env.NEXT_PUBLIC_ENABLE_FREE_SHIPPING,
);

export function isFreeStandardShipping(data: Partial<OrderBig>): boolean {
  const freeShippingPromo: boolean =
    (allEligiblePromos(data)?.filter(
      (promo) =>
        promo.amount === "0.0" &&
        (promo.label.match(/free shipping/gi) ||
          promo.label.match(/complimentary shipping/gi)),
    )?.length ?? 0) > 0;
  return (
    (data.shipTotal === "0.0" && freeShippingPromo) ||
    (data.subscriptionInCart && freeShippingEnabled)
  );
}

export function shouldRenderFreeShippingBar(
  data: Pick<Partial<OrderBig>, "lineItems">,
): boolean {
  const lineItemsWithGiftCards: LineItem[] = data.lineItems?.filter(
    (lineItem) => lineItem.giftCards?.length > 0,
  );
  const lineItemsWithSubs: LineItem[] = data.lineItems?.filter(
    (lineItem) => lineItem.subscriptionLineItems?.length > 0,
  );
  const lineItemsWithSubsAndALC = lineItemsWithSubs?.filter(
    (withSub) => withSub.quantity > withSub.subscriptionLineItems?.length,
  );
  return (
    freeShippingEnabled &&
    lineItemsWithGiftCards?.length === 0 &&
    (data.lineItems?.length > lineItemsWithSubs?.length ||
      lineItemsWithSubsAndALC?.length > 0)
  );
}

type ImageFromChordImageProps = {
  chordImage: ChordImage | undefined;
  defaultAltText?: string;
  defaultImageUrl: string;
};

function chordImageToImage({
  chordImage,
  defaultAltText = "",
  defaultImageUrl,
}: ImageFromChordImageProps): Image {
  return {
    altText: chordImage?.alt || defaultAltText,
    src: chordImage?.smallUrl || defaultImageUrl,
  };
}

/**
 * Transforms an order being returned from Chord into the Order type
 * that Blue Bottle has implemented in the design system.
 */
export function getPriceText(price: Money): string {
  return Dinero({ amount: price.amount }).hasSubUnits()
    ? Dinero({
        amount: price.amount ?? 0,
        currency: price.currency,
      }).toFormat("$0,0.00")
    : Dinero({
        amount: price.amount ?? 0,
        currency: price.currency,
      }).toFormat("$0,0");
}

export const paymentMethodsNormalize = (
  paymentMethods: ChordPayment[],
): Payment[] =>
  paymentMethods.map((payment) => {
    switch (payment.sourceType) {
      case "Spree::StoreCredit":
        return {
          displayAmount: payment.displayAmount,
          state: payment.state,
          source: {
            paymentMethod: "storeCredit",
            id: payment.source?.id,
          },
        } as Payment;
      case "Spree::CreditCard":
      default:
        return {
          state: payment.state,
          /* orders created via OMS as "Free Order" have null payment source data */
          source: {
            paymentMethod: "creditCard",
            id: payment.source?.id,
            month: +(payment.source?.month ?? 0),
            year: +(payment.source?.year ?? 0),
            ccType: payment.source?.ccType,
            lastDigits: +(payment.source?.lastDigits ?? 0),
            name: payment.source?.name,
          },
          displayAmount: payment.displayAmount,
        } as Payment;
    }
  });

export const trimAndReorderDateString = (dateTimeString: string): string => {
  const dateString = dateTimeString.split("T")[0];
  const [year, month, day] = dateString.split("-");
  return `${month}/${day}/${year}`;
};

interface ChordLineItemWithSubscriptionType
  extends ChordLineItem<ChordImage | ChordResponsiveImage> {
  subscriptionType?: string;
}

export function chordOrderAdapter(
  chordOrder: Omit<
    ChordOrder<ChordImage | ChordResponsiveImage>,
    "lineItems"
  > & {
    lineItems: ChordLineItemWithSubscriptionType[];
  },
  monthsText?: string,
): Order {
  const orderContainsPrepaidSubscription = chordOrder.lineItems.find(
    (lineItem) => lineItem.prePaidSubscription,
  );

  const orderAddress = (address?: Address): OrderAddress => ({
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city,
    countryIso: address?.countryIso,
    name: address?.name,
    phone: address?.phone || "",
    stateText: address?.stateText,
    zipCode: address?.zipcode,
  });

  const chordPrepaidSubscriptionRecipientAddress =
    orderContainsPrepaidSubscription?.prePaidSubscription?.recipient?.address;

  const prepaidSubscriptionRecipientAddress: OrderAddress = orderAddress(
    chordPrepaidSubscriptionRecipientAddress,
  );

  const orderShippingAddress: OrderAddress = orderAddress(
    chordOrder.shipAddress,
  );

  return {
    ...chordOrder,
    state: chordOrder.state,
    billAddress: {
      address1: chordOrder.billAddress?.address1,
      address2: chordOrder.billAddress?.address2,
      city: chordOrder.billAddress?.city,
      countryIso: chordOrder.billAddress?.countryIso,
      name: chordOrder.billAddress?.name,
      phone: chordOrder.billAddress?.phone || "",
      stateText: chordOrder.billAddress?.stateText,
      zipCode: chordOrder.billAddress?.zipcode,
    } as OrderAddress,
    channel:
      chordOrder.channel === "subscriptions"
        ? "subscriptions"
        : "one-time-order",
    completedAt: chordOrder.completedAt
      ? new Date(chordOrder.completedAt)
      : undefined,
    createdAt: new Date(chordOrder.createdAt),
    currency: chordOrder.currency as Currency,
    /* totalApplicableStoreCredit is sent as a string via FINALIZE_CHECKOUT but as a number via LOAD_ORDER */
    displayTotalApplicableStoreCredit:
      chordOrder.totalApplicableStoreCredit !== "0.0"
        ? chordOrder.displayTotalApplicableStoreCredit
        : undefined,
    discountTotal: discountTotal(chordOrder as Partial<OrderBig>),
    displayItemTotal: chordOrder.displayItemTotal,
    displayOrderTotalAfterStoreCredit:
      parseFloat(chordOrder.orderTotalAfterStoreCredit) > 0
        ? chordOrder.displayOrderTotalAfterStoreCredit
        : getPriceText({
            amount: 0,
            currency: chordOrder.currency as Currency,
          }),
    displayShipTotal: chordOrder.displayShipTotal,
    displayTaxTotal: chordOrder.displayTaxTotal,
    displayTotal: chordOrder.displayTotal,
    email: chordOrder.email,
    giftFromName: chordOrder.giftNote?.from,
    giftMessage: chordOrder.giftNote?.note,
    giftToName: chordOrder.giftNote?.recipient,
    lineItems: chordOrder.lineItems.map((lineItem) => {
      let desktopImage: Image;
      let mobileImage: Image;

      type DetailOrHistoryImage = ChordResponsiveImage;
      type ConfirmationImage = ChordImage;

      const variantImage: ConfirmationImage | DetailOrHistoryImage =
        lineItem.variant.images[0];

      // @ts-ignore
      if ((variantImage as DetailOrHistoryImage)?.desktop) {
        const image = variantImage as DetailOrHistoryImage;

        desktopImage = image.desktop;
        mobileImage = image.mobile || image.desktop;
      } else {
        const image = variantImage as ConfirmationImage;
        const sharedImage = chordImageToImage({
          chordImage: image,
          defaultImageUrl: variantFallbackImageSmall,
        });

        desktopImage = sharedImage;
        mobileImage = sharedImage;
      }

      const hasLineItemParts = lineItem.parts?.length > 0;

      const lineItemVariant = hasLineItemParts
        ? lineItem.parts?.[0]?.variant
        : lineItem.variant;

      const hasSubLineItems: boolean = !!(
        lineItem.subscriptionLineItems &&
        lineItem.subscriptionLineItems.length > 0
      );
      const isSub: boolean = !!(
        chordOrder.channel === "subscriptions" ||
        hasSubLineItems ||
        lineItem.subscriptionType
      );

      /** start consts for prePaidSubscription block */
      const isPrePaidSub: boolean =
        chordOrder.prePaidSubscriptionInstallment ||
        chordOrder.payments.some(
          (payment) =>
            payment.paymentMethod?.name &&
            payment.paymentMethod?.name === "Pre Paid Payments",
        );
      const { prePaidSubscription } = lineItem;
      const prepaidSingleInstallmentDisplay: string = prePaidSubscription
        ? getPriceText({
            amount: Number(prePaidSubscription?.singleInstallmentPrice) * 100,
            currency: chordOrder.currency as Currency,
          })
        : "";
      const prepaidSubTotal: string = prePaidSubscription
        ? getPriceText({
            amount: Number(prePaidSubscription?.amount) * 100,
            currency: chordOrder.currency as Currency,
          })
        : "";
      const computedSubtotal: string = `${prepaidSingleInstallmentDisplay} x ${prePaidSubscription?.installmentCount} = ${prepaidSubTotal}`;
      const durationDisplayText = (): string => {
        if (prePaidSubscription?.intervalUnits === "week") {
          const duration: number =
            (prePaidSubscription.intervalLength / 4) *
            prePaidSubscription.installmentCount;
          return `${Math.round(duration).toString()} ${monthsText ?? "months"}`;
        }
        return "";
      };
      const intervalUnitDisplayText =
        prePaidSubscription?.intervalLength === 1 ? "week" : "weeks";
      /** use prePaidSubscription data to build display text in lieu of subscriptionLineItems */
      const intervalDisplayText = `${prePaidSubscription?.intervalLength} ${intervalUnitDisplayText}`;

      /** end consts for prePaidSubscription block */

      return {
        id: lineItem.id,
        displayAmount: lineItem.displayAmount,
        giftCards: lineItem.giftCards as any,
        isSub,
        isPrePaidSub,
        ...(prePaidSubscription && {
          prepaidSubscription: {
            computedSubtotal,
            durationDisplayText: durationDisplayText(),
            intervalDisplayText:
              lineItem.subscriptionLineItems?.[0]?.interval ||
              intervalDisplayText, // At time of launch the user can only have 1 prepaid subscription per order
            recipient: {
              address: {
                address1: prePaidSubscription?.recipient?.address?.address1,
                address2: prePaidSubscription?.recipient?.address?.address2,
                city: prePaidSubscription?.recipient?.address?.city,
                countryIso: prePaidSubscription?.recipient?.address?.countryIso,
                name: prePaidSubscription?.recipient?.address?.name,
                phone: "",
                stateText: prePaidSubscription?.recipient?.address?.state?.name,
                zipCode: prePaidSubscription?.recipient?.address?.zipcode,
              },
              email: prePaidSubscription?.recipient?.email,
            },
            startDatePresentationText:
              trimAndReorderDateString(prePaidSubscription?.actionableDate) ||
              undefined,
          },
        }),
        variant: {
          id: lineItemVariant.id,
          displayPrice: lineItemVariant.displayPrice,
          description: lineItemVariant.description,
          inStock: lineItemVariant.inStock,
          optionsText: prePaidSubscription
            ? prePaidSubscription.lineItems[0].variant.optionValues[0]
                .presentation
            : lineItemVariant.optionsText,
          optionValues: lineItemVariant.optionValues.map(
            (optionValue) =>
              ({
                optionTypePresentation: optionValue.optionTypePresentation,
                presentation: optionValue.presentation,
              }) as OptionValue,
          ),
          name: lineItemVariant.name,
          images: [
            {
              desktop: {
                altText: desktopImage.altText,
                src: desktopImage.src,
              },
              mobile: {
                altText: mobileImage.altText,
                src: mobileImage.src,
              },
            },
          ],
          sku: lineItemVariant.sku,
          slug: lineItemVariant.slug,
        },
        subscriptionLineItems:
          lineItem.subscriptionLineItems?.map((subscriptionLineItem) => ({
            intervalLength: subscriptionLineItem.intervalLength,
            intervalUnits: subscriptionLineItem.intervalUnits,
            interval: subscriptionLineItem.interval,
          })) ?? [],
        subscriptionType: lineItem.subscriptionType ?? undefined,
        quantity: lineItem.quantity,
      } as OrderLineItem;
    }),
    number: chordOrder.number,
    payments: paymentMethodsNormalize(chordOrder.payments),
    prePaidSubscriptionInstallment: chordOrder.prePaidSubscriptionInstallment,
    promoLabels: getPromoLabels(chordOrder as Partial<OrderBig>),
    shipAddress: orderContainsPrepaidSubscription
      ? prepaidSubscriptionRecipientAddress
      : orderShippingAddress,
    shipments: chordOrder.shipments.map(
      ({
        // @ts-ignore externalTrackingUrl is missing in Chord's Order type, but present in response
        externalTrackingUrl,
        manifest,
        selectedShippingRate,
        state,
        trackingUrl,
        tracking: number,
      }) => {
        // There is a bug in Chord where their Easypost integration receives a
        // tracking number but no external tracking url for Canadian shipments.
        // In this scenario we can use aftership to build a tracking link.
        const trackingLink =
          number && !externalTrackingUrl
            ? `https://www.aftership.com/track/${number}`
            : externalTrackingUrl;
        return {
          externalTrackingUrl: trackingLink,
          number,
          selectedShippingRate,
          state,
          trackingUrl,
          // @ts-ignore manifest is incorrectly typed in Chord's Order type
          manifest: manifest.map(({ variantId, quantity }) => ({
            variantId,
            quantity,
          })),
        };
      },
    ),
  };
}

type ChordSubDateLineItems = {
  intervalUnits: string;
  intervalLength: number;
  actionableDate?: string;
};

type ShipmentDateObjects = {
  currentShipmentDate: Date;
  nextShipmentDate: Date;
};

/**
 * Uses a Chord Subscription object's line items to generate Date objects
 * for the current shipment date and the next shipment date.
 * @param {ChordSubDateLineItems} dateLineItems - The actionable date,
 *  interval units, and interval length of a subscription.
 * @returns {ShipmentDateObjects} - Date objects representing the current
 *  shipment date and the next shipment date.
 */
export function createShipmentDateObjects(
  dateLineItems: ChordSubDateLineItems,
): ShipmentDateObjects {
  const { actionableDate, intervalUnits, intervalLength } = dateLineItems;
  let currentShipmentDate = new Date();

  if (actionableDate) {
    currentShipmentDate = actionableDate.includes("T")
      ? new Date(actionableDate)
      : new Date(`${actionableDate}T00:00`);
  }

  const nextShipmentDate =
    intervalUnits === "week"
      ? addDays(currentShipmentDate, intervalLength * 7)
      : addMonths(currentShipmentDate, intervalLength);

  return {
    currentShipmentDate,
    nextShipmentDate,
  };
}
/**
 * Sets Iterable campaign and template ID cookies that are used to attribute revenue
 * to marketing campaigns.
 *
 * @see https://support.iterable.com/hc/en-us/articles/205480285-Tracking-Purchases-and-Revenue-#use-browser-cookies-set-by-iterable
 * @export
 */
export function setIterableRevenueAttributionCookies() {
  // Retrieve the Iterable campaign ID from the cookie that Iterable sets
  const iterableEmailCampaignId = cookie.get("iterableEmailCampaignId");
  const iterableTemplateId = cookie.get("iterableTemplateId");

  // Retrieve our exclusion list for Iterable campaigns that we do not
  // want to attribute revenue to, such as the Magic Link campaign.
  // This is read from an environment variable, that is a space
  // delimited list of Iterable campaign IDs we want to exclude.
  const revenueAttributionExclusions: Array<string> =
    process.env.NEXT_PUBLIC_ITERABLE_REVENUE_ATTRIBUTION_CAMPAIGN_EXCLUSIONS?.split(
      ",",
    );

  // If the campaign ID is NOT in our exclusions list, we set new cookies
  // with the Iterable campaign and template Ids
  if (
    iterableEmailCampaignId &&
    !revenueAttributionExclusions?.includes(iterableEmailCampaignId)
  ) {
    cookie.set(
      "iterableEmailCampaignIdRevenueAttributable",
      iterableEmailCampaignId,
    );
    cookie.set(
      "iterableEmailTemplateIdRevenueAttributable",
      iterableTemplateId,
    );
  }
}

/**
 * Assign `key`/`val` pair to `obj` if `val` is not null
 *
 * @param obj Object to which `key`/`val` pair should be assigned
 * @param key Desired key
 * @param val Desired value
 */
export function assignIfExists(obj: object, key: string, val: any) {
  if (val) Object.assign(obj, { [key]: val });
}

/**
 * Checks a product object to see if it has multiple variants. If so, checks that all
 * optionType and optionValue fields are present.
 * @param {AlgoliaRecommendProduct | ConversionSchema} product - The product object from
 * the Algolia Recommend Client or Sanity
 * @param {string} lang - The localized page language
 * @returns {boolean} - true if product only has 1 variant, or if product has multiple
 * variants and all required optionType and optionValue fields. False if otherwise.
 */
export function hasOptionValues(
  product: AlgoliaRecommendProduct | ConversionSchema,
  lang: string,
): boolean {
  // multiple variants require option type and value
  if (product.variants.length > 1) {
    // option type check
    if (!product.optionTypes || product.optionTypes.length === 0) {
      console.log(
        `${product.name[lang]} has multiple variants but no optionType, skipping...`,
      );
      return false;
    }

    // option value check
    // eslint-disable-next-line no-lonely-if
    if (
      !product.variants.every((v) => {
        if (!v.optionValues || v.optionValues.length === 0) {
          console.log(
            `${product.name[lang]} has a variant ${v.label[lang]} with no optionValue`,
          );
          return false;
        } else {
          return true;
        }
      })
    )
      return false;
  }

  return true;
}

/**
 * Checks to see if the provided object has the provided field. If it does,
 * TypeScript will then know that the object provided is of the type T (which
 * you provide).
 *
 * ## Example
 * const someObject = { foo: "bar" };
 *
 * if (hasField<typeof someObject>(someObject, "foo")) {
 *  // TypeScript now knows that someObject is of type { foo: string }
 *  // Do stuff with type safe object here.
 * }
 */
export function isTypeByField<T>(value: Object, field: string): value is T {
  return Object.hasOwn(value, field);
}

/**
 * @param { string } id - The full id of the cafe: "cafe_1"
 * @returns { string } just the number portion of the full id: "1"
 */
export const getCafeId = (id: string): string => id.split("_")[1];

export const getHomepageModules = async <T>(
  modules: {
    module: string;
    pages?: string[];
    excludePages?: string[];
  }[],
  lang: string,
  slug?: string,
) => {
  const homepage = await sanityClient().fetch<Homepage>(`
    *[_type =="homepage" && !(_id in path("drafts.**"))]{
      modules[]{
        ...,
        ${quickShopCarouselCardQuery(lang)}
      }
    }[0]`);

  const findModules = homepage.modules.reduce((prev, homeModule) => {
    const findModule = modules.find(
      (module) => homeModule._type === module.module,
    );

    if (findModule?.excludePages?.includes(slug)) return prev;

    const render = !findModule?.pages || findModule?.pages?.includes(slug);
    if (findModule && render) {
      return {
        ...prev,
        [homeModule._type]: homeModule,
      };
    }
    return prev;
  }, {});

  return findModules as T;
};

export type QuickShopCarouselAndArticleCardCarouselProps = {
  quickShopCarousel: DereferencedQuickShopCarouselProps;
  articleCardCarousel: ArticleCardCarousel;
};

export const quickShopCarouselAndArticleCardCarousel = [
  {
    module: "quickShopCarousel",
    excludePages: [
      "best-sellers",
      "subscriptions",
      "best-selling-blends",
      "best-selling-instant-coffee",
    ],
  },
  {
    module: "articleCardCarousel",
    pages: [
      "best-sellers",
      "subscriptions",
      "best-selling-blends",
      "best-selling-instant-coffee",
    ],
  },
];
