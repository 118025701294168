import { LoginFormCopy } from "@bluebottlecoffee/design-system/components";
import { GiftCardFormCopy } from "@bluebottlecoffee/design-system/components/GiftCard/GiftCardForm";
import {
  AnnouncementBar as AnnouncementBarSchema,
  Footer as FooterSchema,
  Nav as NavSchema,
  TranslationGroupEntry,
} from "../sanity-schema";
import { productRecsQuery, queries, SharedQueryKey } from "./shared";
import { filters, getLocalizedString } from "./utils";

export enum QueryKey {
  ACCOUNT_MENU = "accountMenu",
  LOGIN_DIALOG = "loginDialog",
  PAGE_COPY = "pageCopy",
  SYSTEM = "system",
}

type EntryProjectionType = {
  key: string;
  value: string;
};

export type TranslationGroupSanityType = {
  description: string;
  entries: EntryProjectionType[];
  name: string;
};

export interface SanityQueryType {
  [QueryKey.ACCOUNT_MENU]: TranslationGroupSanityType;
  [SharedQueryKey.ANNOUNCEMENT_BAR]: AnnouncementBarSchema;
  [SharedQueryKey.ARIA]: TranslationGroupSanityType;
  [SharedQueryKey.FOOTER]: FooterSchema;
  [SharedQueryKey.GIFT_CARD_FORM_COPY]: GiftCardFormCopy;
  [QueryKey.LOGIN_DIALOG]: LoginFormCopy;
  [SharedQueryKey.NAV]: NavSchema;
  [QueryKey.SYSTEM]: TranslationGroupSanityType;
}

export const translationGroupProjection = (lang: string) => `{
  description,
  "entries": entries[]{
    key,
    "value": ${getLocalizedString<TranslationGroupEntry>("value", lang)}
  },
  name
}`;

export const buildTranslationGroupQuery = (
  lang: string,
  group: string,
): string => `*[
  ${filters.whereType("translationGroup")} &&
  name == "${group}" &&
  ${filters.excludeDrafts}
][0]${translationGroupProjection(lang)}`;

/**
 * Flattens the array of entries into an object of all the respective keys and
 * values
 *
 * ### Example
 * Converts
 * [{key: someKey, value: someValue}, ...]
 * into
 * {
 *   someKey: someValue,
 *   ...
 * }
 */
export const toCopyObject = <T>(
  lang: string,
  translationGroup: TranslationGroupSanityType,
): T => {
  const copy = {} as T;
  translationGroup?.entries.forEach((entry: EntryProjectionType) => {
    copy[entry.key] = entry.value;
  });
  return copy;
};

/** The Sanity query used to generate the account/[x].ts pages */
export const buildAccountSanityQuery = (
  lang: string,
  translationGroupName: string,
): string => `{
  "${QueryKey.ACCOUNT_MENU}": ${buildTranslationGroupQuery(
  lang,
  "AccountMenu",
)},
  "${SharedQueryKey.ANNOUNCEMENT_BAR}": ${queries.announcementBarQuery},
  "${SharedQueryKey.ARIA}": ${buildTranslationGroupQuery(lang, "Aria")},
  "${SharedQueryKey.COOKIES_NOTICE}": ${queries.cookiesNoticeQuery},
  "${SharedQueryKey.FOOTER}": ${queries.footerQuery},
  "${SharedQueryKey.GIFT_CARD_FORM_COPY}": ${buildTranslationGroupQuery(
  lang,
  "GiftCardFormCopy",
)},
  "${SharedQueryKey.LOGIN_DIALOG}": ${buildTranslationGroupQuery(
  lang,
  "LoginDialog",
)},
"${SharedQueryKey.CONSENT_MANAGER}": ${buildTranslationGroupQuery(
  lang,
  "SegmentConsentManager",
)},
  "${SharedQueryKey.NAV}": ${queries.navQuery},
  "${QueryKey.PAGE_COPY}": ${buildTranslationGroupQuery(
  lang,
  translationGroupName,
)},
"${SharedQueryKey.PRODUCT_RECS}": ${productRecsQuery(lang)},
"${SharedQueryKey.PRODUCT_SEARCH}": ${queries.productSearch(lang)},
"${SharedQueryKey.SYSTEM}": ${buildTranslationGroupQuery(lang, "System")},
}`;
