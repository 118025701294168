/**
 * ANY CHANGES TO THIS FILE MUST ALSO BE MADE TO THE CORRESPONDING CONFIG FILE IN EITHER:
 * - `<project-root>/nextjs/localization.ts`
 * - `<project-root>/studio/localization.ts`
 * DEPENDING ON WHERE THIS FILE IS LOCATED.
 *
 * THEIR CONTENTS MUST BE IDENTICAL!
 */

/**
 * Default languages should be converted to ISO639-1 for html lang in
 * `<project-root>/nextjs/pages/_document.tsx`
 */

export type Region = "ca" | "jp" | "kr" | "us";

export type RegionLongName = "United States of America" | "Canada";

export type Language = {
  code: "eng";
  label: string;
  isDefault: boolean;
};

export type Dataset = {
  name:
    | "development"
    | "us-staging"
    | "us-production"
    | "ca-staging"
    | "ca-production";
  title: string;
  isDefault: boolean;
};

export type RegionConfig = {
  datasets: Dataset[];
  isDefault: boolean;
  longName: RegionLongName;
  languages: Language[];
};

export type LocalizationConfig = {
  [regionCode in Region]: RegionConfig;
};

export type Localization = {
  defaultLanguage(region: string): Language;
  defaultRegion(): Region;
  datasets(region: string): Dataset[];
  languages: (region: string) => Language[];
  regions: LocalizationConfig;
  regionNames(): Region[];
  regionLongName(region: string): RegionLongName;
};

const localization: Localization = {
  languages(region: Region): Language[] {
    return this.regions[region].languages;
  },

  defaultLanguage(region: Region): Language {
    return this.languages(region).find((lang) => lang.isDefault)!;
  },

  defaultRegion(): Region {
    return Object.keys(this.regions).find(
      (region) => this.regions[region].isDefault,
    ) as Region;
  },

  datasets(region: Region): Dataset[] {
    return this.regions[region].datasets;
  },

  regionNames(): Region[] {
    return Object.keys(this.regions) as Region[];
  },

  regionLongName(region: Region): RegionLongName {
    return this.regions[region].longName;
  },

  // this should serve as our global region configuration
  regions: {
    us: {
      isDefault: true,
      datasets: [
        { name: "us-staging" as const, title: "US Staging", isDefault: true },
        {
          name: "us-production" as const,
          title: "US Production",
          isDefault: false,
        },
        {
          name: "development" as const,
          title: "Development",
          isDefault: false,
        },
      ],
      languages: [
        {
          code: "eng" as const,
          label: "English",
          isDefault: true,
        },
      ],
      longName: "United States" as RegionLongName,
    },
    ca: {
      isDefault: false,
      datasets: [
        { name: "ca-staging" as const, title: "CA Staging", isDefault: true },
        {
          name: "ca-production" as const,
          title: "CA Production",
          isDefault: false,
        },
      ],
      languages: [
        {
          code: "eng" as const,
          label: "English",
          isDefault: true,
        },
      ],
      longName: "Canada" as RegionLongName,
    },
    jp: {
      isDefault: false,
      datasets: [],
      languages: [],
      longName: "Japan" as RegionLongName,
    },
    kr: {
      isDefault: false,
      datasets: [],
      languages: [],
      longName: "Korea" as RegionLongName,
    },
  },
};

export default localization;
